
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { newspaperOutline, restaurantOutline, helpBuoyOutline, personOutline } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    return {
      newspaperOutline,
      restaurantOutline,
      helpBuoyOutline,
      personOutline,
    }
  }
}
