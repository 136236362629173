import { createApp } from 'vue'
import App from './App.vue';
import router from './router';

// Directus CMS
import localforage from 'localforage';
import DirectusSDK from '@directus/sdk-js';

// I18N Language Import
import de from './locales/de.json'
import en from './locales/en.json'

import { createI18n } from 'vue-i18n'

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css'

// Directus Init
export const directus = new DirectusSDK('https://cms.app.thb.torben.wtf', {
    auth: {
        storage: localforage,
        mode: 'json',
        autoRefresh: true,
    }
});

// Global loggedIn Var
/*localforage.setItem('loggedIn', false).then(function (value) {
    // Do other things once the value has been saved.
    console.log(value);
}).catch(function(err) {
    // This code runs if there were any errors
    console.log(err);
});*/

// Access Language Files
const messages = {
  en: en,
  de: de,
}

const i18n = createI18n({
  locale: 'de', // set locale
  fallbackLocale: 'de', // set fallback locale
  messages, // set locale messages
})

export const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(i18n);

app.config.globalProperties.loggedIn = false;

router.isReady().then(() => {
  app.mount('#app');
});
