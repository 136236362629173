<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">
        <ion-tab-button
          tab="news"
          href="/tabs/news"
        >
          <ion-icon :icon="newspaperOutline" />
          <ion-label>{{$t('news')}}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="mensa"
          href="/tabs/mensa"
        >
          <ion-icon :icon="restaurantOutline" />
          <ion-label>{{$t('canteen')}}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="service"
          href="/tabs/service"
        >
          <ion-icon :icon="helpBuoyOutline" />
          <ion-label>{{$t('service')}}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="profil"
          href="/tabs/profil"
        >
          <ion-icon :icon="personOutline" />
          <ion-label>{{$t('profile')}}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { newspaperOutline, restaurantOutline, helpBuoyOutline, personOutline } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    return {
      newspaperOutline,
      restaurantOutline,
      helpBuoyOutline,
      personOutline,
    }
  }
}
</script>
