import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'
import { app } from '../main'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/news'
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'news'
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/News.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'news/:id',
        component: () => import('@/views/NewsDetail.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'credits',
        component: () => import('@/views/Credits.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'wetter',
        component: () => import('@/views/Wetter.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'mensa',
        component: () => import('@/views/Mensa.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'map',
        component: () => import('@/views/Map.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'service',
        component: () => import('@/views/Service.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'profil',
        component: () => import('@/views/Profil.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'vbb',
        component: () => import('@/views/VBB.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'stundenplan',
        component: () => import('@/views/Stundenplan.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'ansprechpartner',
        component: () => import('@/views/Ansprechpartner.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'covid',
        component: () => import('@/views/Covid19.vue'),
        meta: {
          requiresAuth: true
        }
      }
     ]
    },
    {
      path: '/auth',
      redirect: '/auth/login'
    },
    {
      path: '/auth/login',
      component: () => import('@/views/Login.vue'),
      name: 'login',
      meta: {
        guest: true
      }
    },
    {
      path: '/auth/logout',
      name: 'logout',
      component: () => import('@/views/Logout.vue'),
      meta: {
        guest: true
      }

  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: () => import('@/views/Tutorial.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (app.config.globalProperties.loggedIn === false) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
